<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="checkAccess('civilPassport', 'editStatus', sailorDocument)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'labelName')
  div(v-if="body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.REJECTED").col-12
    Reject
  div(v-if="body.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.REJECTED").col-12.mt-3
    v-btn(@click="saveNavigatorCertificateStatus" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  name: 'SailorCitizenPassportEditStatus',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: this.sailorDocument.status_document
      },
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose', 'statusById']),
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      sailorInfo: state => state.sailor.sailorInfo
    }),
    listStatuses () {
      let statusesArray = []
      statusesArray = this.statusChoose('StatementSailorPassport')
      statusesArray.push(this.statusById(7))
      statusesArray.push(this.statusById(34))
      return statusesArray
    }
  },
  methods: {
    ...mapActions(['updateSailorCitizenPassportById', 'getSailorInformation']),

    async saveNavigatorCertificateStatus () {
      if (!this.body.status_document) return false
      let data = {
        ...this.$route.params,
        body: { ...this.body }
      }
      this.isLoading = true
      const response = await this.updateSailorCitizenPassportById(data)
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('civilPassport.edited')
      this.isLoading = false
    }
  }
}
</script>
